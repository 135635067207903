import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "description"
}
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mpx_icon = _resolveComponent("mpx-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t("pages.applications.credentials.form.client_id")), 1),
      _createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.clientId) + " ", 1),
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClipboard(_ctx.clientId)))
        }, [
          _createVNode(_component_mpx_icon, { variant: 'file-copy' })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t("pages.applications.credentials.form.client_secret")), 1),
      (_ctx.clientSecret)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createTextVNode(_toDisplayString(_ctx.clientSecret) + " ", 1),
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copyToClipboard(_ctx.clientSecret)))
            }, [
              _createVNode(_component_mpx_icon, { variant: 'file-copy' })
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.clientSecret)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t(
            "pages.create_hmac_auth_application.form.client_secret.description"
          )), 1))
        : _createCommentVNode("", true),
      (!_ctx.clientSecret)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t("pages.applications.credentials.value.hidden_client_secret")), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary field-submit",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.regenerateSecretHmacAuthApplication && _ctx.regenerateSecretHmacAuthApplication(...args)))
      }, _toDisplayString(_ctx.isRegeneratedApplication
            ? _ctx.$t(
                "pages.regenerate_secret_hmac_auth_applications.form.button.saving"
              )
            : _ctx.$t(
                "pages.regenerate_secret_hmac_auth_applications.form.button.update"
              )), 1)
    ])
  ]))
}