
import { defineComponent } from "vue";
import MpxIcon from "@mekari/pixel/src/js/components/Icon/index.vue";
import {
  APPLICATION_REGENRATE_SECRET_HMAC_AUTH,
  APPLICATION_SET_NAME,
} from "@/store-types";
import { SHOW_TOAST } from "@/store-types";
import { TYPE as ToastType } from "vue-toastification";
import { emit } from "@/utils/event";
import { event, evenType } from "@/events/mixpanel/enums/applications";
import { mixpanelInstance } from "@/utils/mixpanel";

interface Data {
  isRegeneratedApplication: boolean;
}

export default defineComponent({
  components: {
    MpxIcon,
  },
  data(): Data {
    return {
      isRegeneratedApplication: false,
    };
  },
  computed: {
    applicationName: {
      // getter
      get: function () {
        return this.$store.state.application.hmacAuthApplication?.name;
      },
      // setter
      set: function (name: string) {
        this.$store.commit(APPLICATION_SET_NAME, name);
      },
    },
    clientId(): string | undefined {
      return this.$store.state.application?.hmacAuthApplication?.clientId;
    },
    clientSecret(): string | undefined {
      return this.$store.state.application?.hmacAuthApplication?.clientSecret;
    },
  },
  methods: {
    async regenerateSecretHmacAuthApplication() {
      if (this.isRegeneratedApplication) {
        return;
      }

      this.isRegeneratedApplication = true;

      const metadata = {
        Remarks: "Success",
        Reason: "Success Regenerate Secret",
        CompanySSOID:
          this.$store.state.application.hmacAuthApplication?.companyId,
      };
      const dataEvent = {
        Remarks: "Success",
        Reason: "Success Regenerate Secret",
      };

      try {
        await this.$store.dispatch(APPLICATION_REGENRATE_SECRET_HMAC_AUTH, {
          uuid: this.$store.state.application.hmacAuthApplication?.id,
          pageName: "Applications",
        });
        mixpanelInstance.setMetadata(metadata);
        emit(event.RegenerateSecretApplication, dataEvent);
      } catch (err) {
        dataEvent.Remarks = "Failed";
        dataEvent.Reason = (err as any).response
          ? (err as any).response.data.message
          : "Something went wrong. Try again";
        mixpanelInstance.setMetadata(metadata);
        emit(event.RegenerateSecretApplication, dataEvent);
        this.handleError(err);
      } finally {
        this.isRegeneratedApplication = false;
      }
    },

    async copyToClipboard(text: string | any) {
      await navigator.clipboard.writeText(text);
    },

    handleError(err: Error | any) {
      this.$store.dispatch(SHOW_TOAST, {
        message: err.message,
        type: ToastType.ERROR,
      });
    },
  },
});
